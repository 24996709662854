import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  render() {
    const { t } = this.props
    return (
      <motion.footer
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
        className="bg-gray-900 text-white py-12"
      >
        <div className="max-w-6xl mx-auto px-4">
          {/* Grid Layout */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* About Section */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-bold mb-4">{t('about_title')}</h3>
              <p className="text-gray-400">
                {t('about_description')}
              </p>
            </motion.div>

            {/* Quick Links */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-bold mb-4">{t('quick_links_title')}</h3>
              <ul className="text-gray-400">
                <li className="mb-2"><a href="#home" className="hover:text-blue-500">{t('home')}</a></li>
                <li className="mb-2"><a href="#features" className="hover:text-blue-500">{t('features')}</a></li>
                <li className="mb-2"><a href="#coach" className="hover:text-blue-500">{t('about_coach')}</a></li>
                <li className="mb-2"><a href="#results" className="hover:text-blue-500">{t('client_results')}</a></li>
                <li className="mb-2"><a href="#faq" className="hover:text-blue-500">{t('faq')}</a></li>
                <li className="mb-2"><a href="#pricing" className="hover:text-blue-500">{t('pricing')}</a></li>
                <li className="mb-2"><a href="#contact" className="hover:text-blue-500">{t('contact_me')}</a></li>
              </ul>
            </motion.div>

            {/* Contact Info */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-bold mb-4">{t('contact_title')}</h3>
              <ul className="text-gray-400">
                <li className="mb-2">
                  {t('email_title')}: <a href="mailto:support@diegotrainer.com" className="text-blue-500 hover:underline">support@diegotrainer.com</a>
                </li>
              </ul>
            </motion.div>

            {/* Social Media */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.8, duration: 0.8 }}
              viewport={{ once: true }}
            >
              <h3 className="text-xl font-bold mb-4">{t('follow_me_title')}</h3>
              <div className="flex space-x-4">
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/DiegoTrainer/"
                  className="text-gray-400 hover:text-blue-500"
                >
                  <FaFacebook className="w-6 h-6" />
                </motion.a>
                <motion.a
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  target="_blank" 
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/diego.trainer"
                  className="text-gray-400 hover:text-blue-500"
                >
                  <FaInstagram className="w-6 h-6" />
                </motion.a>
              </div>
            </motion.div>
          </div>

          {/* Ebook Disclaimer with Animation */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.8 }}
            viewport={{ once: true }}
            className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400 text-xs space-y-2"
          >
            <motion.p 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7 }}
            >
              ✉️ {t('ebook_disclaimer')}
            </motion.p>
            
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.9 }}
            >
              🔄 {t('ebook_cancel_disclaimer')}
            </motion.p>
            
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.1 }}
            >
              💳 {t('billing_disclaimer')}
            </motion.p>
          </motion.div>

          {/* terms of use */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.3, duration: 0.8 }}
            viewport={{ once: true }}
            className="text-center mt-4"
          >
            <Link to="/terms-of-use" className="text-gray-400 text-sm font-semibold hover:text-blue-700 hover:underline">
              {t('terms_of_use_title')}
            </Link>
          </motion.div>

          {/* Copyright Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.8 }}
            viewport={{ once: true }}
            className="border-t border-gray-400 mt-8 pt-8 text-center text-gray-400"
          >
            <p>&copy; {new Date().getFullYear()} Diego Trainer. {t('all_rights_reserved')}</p>
          </motion.div>
        </div>
      </motion.footer>
    );
  }
}

export default withTranslation()(Footer);