import React from 'react';
import { motion } from 'framer-motion';
import { VscBook } from 'react-icons/vsc';
import { image } from 'framer-motion/client';
import { withTranslation } from 'react-i18next';

class Hero extends React.Component {
    render() {
      const { t, i18n } = this.props
      const currentLanguage = i18n.language; // Get current language
      return (
        <section id="home" className="bg-gray-900 text-white py-20 px-4">
          <div className="max-w-6xl mx-auto text-center">
            <h1 className={currentLanguage==='th' ? "text-5xl font-bold mb-6 leading-tight" : "text-5xl font-bold mb-6"}>
              {t("burn_with_dt")}<br />
              <span className="text-blue-500">{t("free_15_day_trial")}</span>{t("plus_ebook")}
            </h1>
            <div className="mb-8 flex items-center justify-center space-x-4">
              <VscBook className="text-blue-500 text-4xl" />
              <span className="text-xl">{t("ebook_included")}</span>
            </div>
            <a 
              href="https://training-app.short.gy/train-with-diego-trainer" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-lg text-lg mb-4">
                {t("claim_trial_and_ebook")}
              </button>
            </a>

            <p className="text-sm text-gray-300">
              {t("hero_trial_disclaimer")}
            </p>

            {/* App Screenshots Section */}
            <motion.div 
              initial={{ opacity: 0 }}
              // whileInView={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className="mt-12"
            >
              <div className="flex overflow-x-auto pb-4 gap-4 justify-start md:justify-center">
                <motion.div 
                  whileHover={{ scale: 1.05 }}
                  className="flex-shrink-0 w-48 relative rounded-lg shadow-xl"
                  initial={{ x: -50 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: image.delay, duration: 0.8 }}
                  viewport={{ once: true }}
                  // whileInView={{ x: 0 }}
                >
                  <img 
                    src="/assets/screens/workout-plan.png" 
                    alt="Workout Plan" 
                    className="w-full h-auto object-contain rounded-lg border-2 border-gray-800"
                  />
                </motion.div>
  
                <motion.div 
                  whileHover={{ scale: 1.05 }}
                  className="flex-shrink-0 w-48 relative rounded-lg shadow-xl"
                  initial={{ x: -50 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: image.delay, duration: 0.8 }}
                  viewport={{ once: true }}
                  // whileInView={{ x: 0 }}
                  // transition={{ delay: 0.2 }}
                >
                  <img 
                    src="/assets/screens/workout-tracker.png" 
                    alt="Workout Tracking" 
                    className="w-full h-auto object-contain rounded-lg border-2 border-gray-800"
                  />
                </motion.div>
  
                <motion.div 
                  whileHover={{ scale: 1.05 }}
                  className="flex-shrink-0 w-48 relative rounded-lg shadow-xl"
                  initial={{ x: -50 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: image.delay, duration: 0.8 }}
                  viewport={{ once: true }}
                  // whileInView={{ x: 0 }}
                  // transition={{ delay: 0.4 }}
                >
                  <img 
                    src="/assets/screens/body-tracker.png" 
                    alt="Progress Tracking" 
                    className="w-full h-auto object-contain rounded-lg border-2 border-gray-800"
                  />
                </motion.div>
              </div>
  
              <p className="mt-6 text-gray-400 text-sm px-4">
                {t("swipe_for_features")}
              </p>
            </motion.div>
          </div>
        </section>
      );
    }
  }

  export default withTranslation()(Hero)