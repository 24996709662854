import React from 'react';
import { motion } from 'framer-motion';
import { FaFire, FaDumbbell, FaAppleAlt, FaMobileAlt } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';

class MethodSection extends React.Component {
  render() {
    const { t } = this.props
    return (
      <section className="py-16 bg-gray-900 text-white" id="method">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center"
          >
            {/* Content Column */}
            <div>
              <motion.h2 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                viewport={{ once: true }}
                className="text-4xl font-bold mb-8"
              >
                {t("science_based_fat_loss")}
                <span className="block text-blue-500 text-xl mt-3">{t("years_perfected")}</span>
              </motion.h2>

              <div className="space-y-6">
                <motion.div 
                  className="flex items-start"
                  initial={{ x: -50 }}
                  whileInView={{ x: 0 }}
                  viewport={{ once: true }}
                >
                  <div className="bg-blue-500 p-3 rounded-lg mr-4">
                    <FaFire className="text-white text-2xl" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t("metabolic_ignition")}</h3>
                    <p className="text-gray-300">{t("metabolic_ignition_desc")}</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start"
                  initial={{ x: -50 }}
                  whileInView={{ x: 0 }}
                  transition={{ delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  <div className="bg-blue-500 p-3 rounded-lg mr-4">
                    <FaDumbbell className="text-white text-2xl" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t("strength_preservation")}</h3>
                    <p className="text-gray-300">{t("strength_preservation_desc")}</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start"
                  initial={{ x: -50 }}
                  whileInView={{ x: 0 }}
                  transition={{ delay: 0.4 }}
                  viewport={{ once: true }}
                >
                  <div className="bg-blue-500 p-3 rounded-lg mr-4">
                    <FaAppleAlt className="text-white text-2xl" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t("sustainable_nutrition")}</h3>
                    <p className="text-gray-300">{t("sustainable_nutrition_desc")}</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-start"
                  initial={{ x: -50 }}
                  whileInView={{ x: 0 }}
                  transition={{ delay: 0.6 }}
                  viewport={{ once: true }}
                >
                  <div className="bg-blue-500 p-3 rounded-lg mr-4">
                    <FaMobileAlt className="text-white text-2xl" />
                  </div>
                  <div>
                    <h3 className="text-xl font-semibold mb-2">{t("adaptive_coaching")}</h3>
                    <p className="text-gray-300">{t("adaptive_coaching_desc")}</p>
                  </div>
                </motion.div>
              </div>
              <a 
                href="https://training-app.short.gy/train-with-diego-trainer" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  className="mt-8 bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-lg"
                >
                  {t("start_custom_plan")}
                </motion.button>
              </a>
            </div>

            {/* Image Column */}
            <motion.div 
              className="relative"
              initial={{ opacity: 0, scale: 0.9 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true }}
            >
              <img 
                src="/method-visual.png" 
                alt={t("fat_loss_method")}
                className="rounded-xl shadow-2xl"
              />
              <div className="absolute -bottom-6 -left-6 bg-blue-900 text-blue-100 p-4 rounded-xl shadow-lg">
                <div className="text-2xl font-bold">83%</div>
                <div className="text-sm">{t("average_success_rate")}</div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(MethodSection);
