import React from 'react';
import { withTranslation } from 'react-i18next';
import { FaBookReader, FaClock } from 'react-icons/fa';
import { GiWeightLiftingUp } from 'react-icons/gi';

class TrialCTA extends React.Component {
  render() {
      const { t } = this.props
      return (
        <section className="bg-gray-50 py-16">
          <div className="max-w-6xl mx-auto px-4 text-center">
            <div className="bg-white rounded-2xl shadow-lg p-8">
              <h2 className="text-3xl font-bold mb-6">
                {t('trial_what_you_get')}
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                <div>
                  <GiWeightLiftingUp className="text-blue-500 text-4xl mx-auto mb-4" />
                  <h3 className="text-xl font-semibold">{t('trial_full_access')}</h3>
                  <p className="text-gray-600">{t('trial_workouts_tracking_support')}</p>
                </div>
                <div>
                  <FaBookReader className="text-blue-500 text-4xl mx-auto mb-4" />
                  <h3 className="text-xl font-semibold">{t('trial_free_ebook')}</h3>
                  <p className="text-gray-600">{t('trial_fat_loss_blueprint')}</p>
                </div>
                <div>
                  <FaClock className="text-blue-500 text-4xl mx-auto mb-4" />
                  <h3 className="text-xl font-semibold">{t('trial_no_risk')}</h3>
                  <p className="text-gray-600">{t('trial_cancel_anytime')}</p>
                </div>
              </div>
              <a 
                href="https://training-app.short.gy/train-with-diego-trainer" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-8 rounded-lg text-lg">
                  {t('trial_cta_button')}
                </button>
              </a>
            </div>
          </div>
        </section>
      );
    }
}

export default withTranslation()(TrialCTA);
