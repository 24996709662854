import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './locales/en/translation.json';
import ptBRTranslations from './locales/pt-BR/translation.json';
import thTranslations from './locales/th/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      'pt-BR': { translation: ptBRTranslations },
      th: { translation: thTranslations }
    },
    fallbackLng: 'en',
    interpolation: { escapeValue: false }
  });

export default i18n;