import React from 'react';
import { motion } from 'framer-motion';
import { FaBookOpen, FaDumbbell, FaMobileAlt, FaChartLine, FaComments } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';

class Features extends React.Component {
  render() {
    const { t } = this.props
    const features = [
      {
        icon: <FaBookOpen />, 
        title: t("instant_ebook_access"), 
        text: t("instant_ebook_access_text")
      },
      { 
        icon: <FaDumbbell />, 
        title: t("custom_fat_loss_plan"), 
        text: t("custom_fat_loss_plan_text") 
      },
      { 
        icon: <FaMobileAlt />, 
        title: t("app_integration"), 
        text: t("app_integration_text") 
      },
      { 
        icon: <FaChartLine />, 
        title: t("progress_tracking"), 
        text: t("progress_tracking_text") 
      },
      { 
        icon: <FaComments />, 
        title: t("ongoing_expert_support"), 
        text: t("ongoing_expert_support_text") 
      }
    ];
    return (
      <section id="features" className="py-16 bg-white scroll-mt-20">
        <div className="max-w-6xl mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-3xl font-bold text-center mb-12"
          >
            {t("complete_transformation_toolkit")}
          </motion.h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div 
                key={index}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.15 }}
                viewport={{ once: true }}
                className={`text-center p-6 rounded-xl shadow-lg hover:shadow-xl transition-all ${
                  feature.title === t("ongoing_expert_support")
                    ? "bg-blue-50 border-2 border-blue-200" 
                    : "bg-white"
                }`}
              >
                <div className="text-4xl text-blue-500 mb-4 flex justify-center">
                  {feature.icon}
                </div>
                <h3 className="text-xl font-bold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.text}</p>
                
                {feature.title === t("ongoing_expert_support") && (
                  <div className="mt-4">
                    <span className="inline-block bg-blue-100 text-blue-600 px-3 py-1 rounded-full text-sm font-semibold">
                      {t("coach_powered_progress")}
                    </span>
                  </div>
                )}
              </motion.div>
            ))}
          </div>

          {/* Visual Connector for Odd Numbered Grid */}
          <div className="mt-8 flex justify-center">
            <div className="h-1 w-32 bg-blue-100 rounded-full" />
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(Features);