import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class TermsOfUse extends Component {

    componentDidMount() {
        document.body.classList.add('terms-of-use-page'); // Add the class when the component mounts
        window.scrollTo(0, 0); // Scroll to the top of the page
    }

    componentWillUnmount() {
    document.body.classList.remove('terms-of-use-page'); // Remove the class when the component unmounts
    }

    render() {
        const { t } = this.props;
        return (
        <div className="bg-gradient-to-r from-blue-100 via-white to-blue-100 min-h-screen py-16 terms-of-use-page">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold text-center text-blue-700 mb-8">
                {t('termsOfUse.title')}
            </h1>
            <p className="text-gray-700 mb-5">{t('terms_last_updated_on')}</p>
            <div className="prose lg:prose-lg max-w-none bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-800 font-semibold">{t('termsOfUse.purpose.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.purpose.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.warranty.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.warranty.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.liability.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.liability.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.termination.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.termination.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.indemnification.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.indemnification.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.privacyPolicy.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.privacyPolicy.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.userContent.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.userContent.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.governingLaw.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.governingLaw.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.compliance.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.compliance.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.warranties.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.warranties.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.limitedLiability.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.limitedLiability.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.prohibitedUses.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.prohibitedUses.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.severability.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.severability.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.noLicense.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.noLicense.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.modifications.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.modifications.content')}</p>

                <p className="text-gray-800 font-semibold">{t('termsOfUse.acknowledgement.title')}</p>
                <p className="text-gray-700">{t('termsOfUse.acknowledgement.content')}</p>
            </div>
            </div>
        </div>
        );
    }
}

export default withTranslation()(TermsOfUse);
