import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FiChevronDown } from 'react-icons/fi';
import { withTranslation } from 'react-i18next';

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuestionId: null
    };
  }

  toggleQuestion = (id) => {
    this.setState(prevState => ({
      activeQuestionId: prevState.activeQuestionId === id ? null : id
    }));
  };

  render() {
    const { t } = this.props
    const faqs = [
      {
        id: 1,
        question: t("question1"),
        answer: t("answer1"),
        category: 'program'
      },
      {
        id: 2,
        question: t("question2"),
        answer: t("answer2"),
        category: 'expertise'
      },
      {
        id: 3,
        question: t("question3"),
        answer: t("answer3"),
        category: 'program'
      },
      {
        id: 4,
        question: t("question4"),
        answer: t("answer4"),
        category: 'support'
      },
      {
        id: 5,
        question: t("question5"),
        answer: t("answer5")
      },
      {
        id: 6,
        question: t("question6"),
        answer: t("answer6")
      },
    ];

    return (
      <section id="faq" className="py-16 bg-gray-50 scroll-mt-20">
        <div className="max-w-6xl mx-auto px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            className="text-3xl font-bold text-center mb-12"
          >
            {t("title")}
          </motion.h2>

          <div className="max-w-3xl mx-auto">
            {faqs.map((faq) => (
              <motion.div 
                key={faq.id}
                className="mb-4 bg-white rounded-lg shadow-sm"
                whileHover={{ scale: 1.01 }}
              >
                <button
                  onClick={() => this.toggleQuestion(faq.id)}
                  className="w-full text-left px-6 py-4 flex justify-between items-center"
                >
                  <span className="text-lg font-semibold pr-4">{faq.question}</span>
                  <motion.span
                    animate={{ rotate: this.state.activeQuestionId === faq.id ? 180 : 0 }}
                  >
                    <FiChevronDown className="text-blue-500 text-xl" />
                  </motion.span>
                </button>

                <AnimatePresence>
                  {this.state.activeQuestionId === faq.id && (
                    <motion.div
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      className="overflow-hidden"
                    >
                      <div className="px-6 pb-4 pt-2 border-t border-blue-100">
                        <p className="text-gray-600 leading-relaxed">
                          {faq.answer}
                        </p>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(FAQ);
