import React from 'react';
import { withTranslation } from 'react-i18next';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
      currentLang: props.i18n.language // Set initial language from i18n
    };
  }

  componentDidMount() {
    // Set the current language in the state after the component mounts
    this.setState({ currentLang: this.props.i18n.language });
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.state.isMenuOpen !== prevState.isMenuOpen) {
  //     const isMobile = window.innerWidth < 768; // Adjust breakpoint if needed
  //     document.body.style.overflow = this.state.isMenuOpen && isMobile ? 'hidden' : 'auto';
  //   }
  // }

  // componentWillUnmount() {
  //   document.body.style.overflow = 'auto';
  // }

  // componentDidUpdate(_, prevState) {
  //   if (prevState.isMenuOpen !== this.state.isMenuOpen) {
  //     if (this.state.isMenuOpen) {
  //       document.body.classList.add("overflow-hidden");
  //     } else {
  //       document.body.classList.remove("overflow-hidden");
  //     }
  //   }
  // }

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
    this.setState({ isMenuOpen: false, currentLang: lng });
  };

  render() {
    const { t } = this.props;
    const navigation = [
      { name: t('features'), href: '#features' },
      { name: t('about_coach'), href: '#coach' },
      { name: t('client_results'), href: '#results' },
      { name: t('faq'), href: '#faq' },
      { name: t('pricing'), href: '#pricing' },
      { name: t('contact_me'), href: '#contact' }
    ];

    return (
      <header className="sticky top-0 bg-gray-900 z-50 shadow-lg">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo - Updated with image */}
            <div className="flex-shrink-0">
              <a
                href='#'
                onClick={() => this.setState({ isMenuOpen: false })}
              >
                <img
                  src="/assets/logo.png" // Update this path to your logo
                  alt="Diego Trainer Logo"
                  className="h-12 w-auto" // Adjust size as needed
                />
                </a>
              </div>

            {/* Mobile Menu Button */}
            <div>
              <button
                onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}
                className="text-gray-300 hover:text-white"
              >
                {this.state.isMenuOpen ? (
                  <XMarkIcon className="h-6 w-6" />
                ) : (
                  <Bars3Icon className="h-6 w-6" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile & Desktop Sliding Menu */}
          {this.state.isMenuOpen && (
            <motion.div 
              initial={{ x: "100%" }} 
              animate={{ x: 0 }} 
              exit={{ x: "100%" }} 
              transition={{ duration: 0.3 }}
              className="absolute top-full right-0 w-full md:w-1/2 bg-gray-900 shadow-lg p-6"
            >
              {/* Menu Items */}
              <nav className="space-y-4">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block text-gray-300 hover:text-white text-lg"
                    onClick={() => this.setState({ isMenuOpen: false })}
                  >
                    {item.name}
                  </a>
                ))}
              </nav>

              {/* Language Switcher */}
              <div className="mt-6">
                <LanguageSwitcher 
                  currentLang={this.props.i18n.language} 
                  onChange={this.changeLanguage} 
                />
              </div>
            </motion.div>
          )}
        
        </nav>
      </header>
    );
  }
}

const LanguageSwitcher = ({ currentLang, onChange }) => (
  <div className="flex space-x-2">
    <button
      onClick={() => onChange('en')}
      className={`px-3 py-1 rounded ${
        currentLang === 'en' ? 'bg-blue-500 text-white' : 'text-gray-300'
      }`}
    >
      EN
    </button>
    <button
      onClick={() => onChange('pt-BR')}
      className={`px-3 py-1 rounded ${
        currentLang === 'pt-BR' ? 'bg-blue-500 text-white' : 'text-gray-300'
      }`}
    >
      PT
    </button>
    <button
      onClick={() => onChange('th')}
      className={`px-3 py-1 rounded ${
        currentLang === 'th' ? 'bg-blue-500 text-white' : 'text-gray-300'
      }`}
    >
      ภาษาไทย
    </button>
  </div>
);

export default withTranslation()(Header);