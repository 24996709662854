import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userEmail: '',
      userMessage: '',
      isLoading: false,
      error: null,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSendMessage = async () => {
    const { userName, userEmail, userMessage } = this.state;

    if (userName.trim() === '' || userEmail.trim() === '' || userMessage.trim() === '') {
      this.setState({ error: "error_all_fields_required" });
      return;
    }

    if (!this.validateEmail(userEmail)) {
      this.setState({ error: "error_invalid_email" });
      return;
    }

    const data = { userName, userEmail, userMessage };

    this.setState({ isLoading: true, error: null });

    try {
      await this.sendContactMessage(data);
      const { t } = this.props
      alert(t("message_success"));
      this.resetForm();
    } catch (error) {
      this.setState({ error: "message_failure" });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  sendContactMessage = async (data) => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };

  sendContactMessage = async (data) => {
    const rawUrl = 'https://api.diegotrainer.com/'
    const url = rawUrl + 'api/user/websitepayment/';
    const response = await fetch(url + 'sendcontactmessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  
    // if (!response.ok) {
    if (response.error) {
      throw new Error('Failed to send message.');
    }
  
    return response.json();
  };

  validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  resetForm = () => {
    this.setState({ userName: '', userEmail: '', userMessage: '', error: null });
  };

  render() {
    const { userName, userEmail, userMessage, isLoading, error } = this.state;
    const { t } = this.props;

    return (
        <section id="contact" className='-mt-10 scroll-mt-20'>
      <div className="max-w-3xl mx-auto p-6 bg-gray-100 shadow-lg rounded-lg my-4 sm:my-8">
        <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-gray-800">{t("contact_me")}</h2>

        {error && <div className="text-red-500 mb-4 text-center">{t(error)}</div>}

        <div className="mb-4">
          <label className="block text-sm sm:text-base font-medium mb-1 text-gray-700" htmlFor="userName">
            {t("name")}
          </label>
          <input
            type="text"
            id="userName"
            name="userName"
            value={userName}
            onChange={this.handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={t("placeholder_name")}
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm sm:text-base font-medium mb-1 text-gray-700" htmlFor="userEmail">
            {t("email")}
          </label>
          <input
            type="email"
            id="userEmail"
            name="userEmail"
            value={userEmail}
            onChange={this.handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={t("placeholder_email")}
            required
          />
        </div>

        <div className="mb-6">
          <label className="block text-sm sm:text-base font-medium mb-1 text-gray-700" htmlFor="userMessage">
            {t("message")}
          </label>
          <textarea
            id="userMessage"
            name="userMessage"
            value={userMessage}
            onChange={this.handleInputChange}
            className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder={t("placeholder_message")}
            rows="4"
            required
          />
        </div>

        <button
          onClick={this.handleSendMessage}
          disabled={isLoading}
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          {isLoading ? t("sending") : t("send_message")}
        </button>
      </div>
      </section>
    );
  }
}

  export default withTranslation()(ContactForm)