import React from 'react';
import Hero from '../components/Hero';
import HowItWorks from '../components/HowItWorks';
import Testimonials from '../components/Testimonials';
import Features from '../components/Features';
import Expertise from '../components/Expertise';
import MeetCoach from '../components/MeetCoach';
import CTA from '../components/CTA';
import TrialCTA from '../components/TrialCTA';
import MethodSection from '../components/MethodSection';
import FAQ from '../components/FAQ';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';

class Home extends React.Component {
  render() {
    return (
      <div className="overflow-hidden">
        <Hero />
        <HowItWorks />
        <Testimonials />
        <Features />
        <Expertise />
        <MeetCoach />
        <CTA />
        <TrialCTA />
        <MethodSection />
        <FAQ />
        <ContactForm />
        <Footer />
      </div>
    );
  }
}

export default Home;