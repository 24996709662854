import React from 'react';
import { motion } from 'framer-motion';
import { FaDumbbell, FaMedal } from 'react-icons/fa';
import { withTranslation } from 'react-i18next';

class MeetCoach extends React.Component {
  render() {
    const { t } = this.props
    return (
      <section id="coach" className="py-16 bg-white scroll-mt-20">
        <div className="max-w-6xl mx-auto px-4">
          <motion.div 
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
          >
            {/* Coach Photo */}
            <div className="relative group">
              <img 
                src="/assets/coach-photo.jpg" 
                alt={t("coach_diego_trainer")}
                className="rounded-xl shadow-2xl transform group-hover:scale-105 transition-transform duration-300 max-w-full h-auto"
              />
              <div className="absolute -bottom-6 right-6 bg-blue-500 text-white p-4 rounded-lg shadow-lg">
                <span className="block text-2xl font-bold">12+</span>
                <span className="text-sm">{t("years_experience")}</span>
              </div>
            </div>

            {/* Coach Bio */}
            <div>
              <motion.h2 
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                viewport={{ once: true }}
                className="text-4xl font-bold mb-6"
              >
                {t("meet_your_fat_loss_expert")}
                <span className="block text-blue-500 text-3xl mt-2">{t("coach_diego_trainer")}</span>
              </motion.h2>

              <p className="text-lg text-gray-600 mb-8">
                {t("coach_bio")}
              </p>

              {/* Credentials Grid */}
              <div className="grid grid-cols-2 gap-6 mb-8">
                <motion.div 
                  className="flex items-center"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                >
                  <FaMedal className="text-blue-500 text-2xl mr-3" />
                  <div>
                    <h3 className="font-bold">{t("specialization")}</h3>
                    <p className="text-gray-600 text-sm">{t("body_fat_reduction")}</p>
                  </div>
                </motion.div>

                <motion.div 
                  className="flex items-center"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ delay: 0.2 }}
                  viewport={{ once: true }}
                >
                  <FaDumbbell className="text-blue-500 text-2xl mr-3" />
                  <div>
                    <h3 className="font-bold">{t("training_style")}</h3>
                    <p className="text-gray-600 text-sm">{t("metabolic_conditioning")}</p>
                  </div>
                </motion.div>
              </div>
            </div>
          </motion.div>

          {/* Signature */}
          <div className="mt-8 text-center">
            <motion.img 
              src="/assets/signature.png" 
              alt={t("coach_signature")}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 0.75 }}
              transition={{ delay: 0.4 }}
              viewport={{ once: true }}
              className="mx-auto w-48"
            />
            <motion.p 
              className="text-gray-600 mt-4 italic"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 0.6 }}
              viewport={{ once: true }}
            >
              {t("coach_mission")}
            </motion.p>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(MeetCoach);