import React, { Suspense } from 'react';
import Header from './components/Header';
import Home from './pages/Home';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
// import { Router } from 'react-router-dom';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import TermsOfUse from './pages/TermsOfUse';

class App extends React.Component {
  render() {
    return (
      <I18nextProvider i18n={i18n}>
      <Router>
        <div className="min-h-screen bg-gray-50">
          <Suspense fallback="Loading...">
            <Header />
            <main>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                </Routes>
              </main>
          </Suspense>
        </div>
      </Router>
    </I18nextProvider>
    );
  }
}

export default App;